import { render, staticRenderFns } from "./UPC.vue?vue&type=template&id=c062afbc&scoped=true"
import script from "./UPC.vue?vue&type=script&lang=js"
export * from "./UPC.vue?vue&type=script&lang=js"
import style0 from "./UPC.vue?vue&type=style&index=0&id=c062afbc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c062afbc",
  null
  
)

export default component.exports