import { render, staticRenderFns } from "./flame-resistant.vue?vue&type=template&id=ca66b4a2"
import script from "./flame-resistant.vue?vue&type=script&lang=js"
export * from "./flame-resistant.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports