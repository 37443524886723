<template>

    <div>
        <div class="filter-heading" v-if="!checkforfilter">
            <h3 v-if="selectedSubCat && categories && filterCategories">
                {{filterCategories.commodity_code.find(element=>element.id==currentParam.ccID).title}}
            </h3>
            <h3 v-else-if="selectedCat && categories && filterCategories" class="mb-5">
                {{filterCategories.select_code.title}}
            </h3>
            <p v-if="selectedSubCat && categories" class="text-uppercase">
                <router-link
                    :to="{
                        name: 'products',
                        query: {
                            scID: currentParam.scID,
                        }
                    }" >
                    <i class="fa fa-angle-left" aria-hidden="true"></i> {{ filterCategories.select_code.title }}
                </router-link>
            </p>
        </div>
        <!-- check if search is set -->
        <div v-else-if="this.currentParam.hasOwnProperty('q') && this.currentParam.hasOwnProperty('v')" class="filter-heading">
            <h3>Search Results</h3>
        </div>

        <div class="filter-dropdownbar">

            <p data-toggle="collapse" data-target="#filterList" aria-expanded="false" aria-controls="collapse" role="button" class="collapsed"><span>Filter<i class="fa fa-angle-down" aria-hidden="true"></i></span></p>

            <p class="prod-layout-icon"><strong @click="switchProdLayout('horizontal-layout')"><i class="fa fa-list" aria-hidden="true"></i>
            </strong><strong @click="switchProdLayout('vertical-layout')"><i class="fa fa-th-large prod-layout-select" aria-hidden="true"></i></strong></p>
        </div>

        <div class="filters-layout  pt-3 collapse" :class="{'show':showFilter}" id="filterList">
            <h5 class="text-uppercase text-dark ">
                Smart Filters:
            </h5>

            <!-- display is no filter or search is selected -->
            <div v-if="checkforfilter">
                <smart-filter name="Categories" >
                    <div class="filter">
                        <ul class="subcategories">

                            <li
                                v-for="(category,
                                index) in categories"
                                :key="index"
                            >
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            scID: category.select_code.id
                                        }
                                    }"
                                    v-html="category.select_code.title"
                                >
                                </router-link>
                            </li>
                        </ul>
                    </div>

                </smart-filter>
            </div>

            <!-- display only if scID  is selected -->
            <div v-if="!selectedSubCat && selectedCat">
                <smart-filter name="Categories" >
                    <div class="filter">
                        <ul class="subcategories">

                            <li
                                v-for="(subcategory,
                                index) in filterCategories.commodity_code"
                                :key="index"
                            >
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: {
                                            scID: filterCategories.select_code.id,
                                            ccID: subcategory.id
                                        }
                                    }"
                                    v-html="subcategory.title"
                                >
                                </router-link>
                            </li>
                        </ul>
                    </div>

                </smart-filter>
            </div>


            <!-- display smart filters only if both scID and ccID is selected -->
            <section v-if="selectedSubCat && selectedCat && categories">

                <div v-for="(filter,index) in filterOptions" :key="index">

                    <smart-filter :setid="filter.filter" :name="filter.title" :show="filter.taxonomy.filter_show">
                        <div  class="filter collapse" :class="{'show':filter.taxonomy.filter_show}" :id=filter.filter>

                            <!-- display icons for filter if the filter type is icon -->
                            <div v-if="filter.filter_type=='icon'">
                                <ul class="d-flex flex-wrap">
                                    <li v-for="(feature,index) in filter.features" :key="index">
                                        <span :class="{'filterSelected':isSelected(filter.filter,feature)}" @click="updateParams(filter.filter,feature,isSelected(filter.filter,feature))" class="filter-icon" v-html="getIcon(filter.filter,feature)">
                                        </span>
                                    </li>
                                </ul>

                            </div>
                            <!-- display checkbox for all the other filter type -->
                            <div v-else>
                                <ul >
                                    <li v-for="(feature,index) in filter.features" :key="index" @click="updateParams(filter.filter,feature,isSelected(filter.filter,feature))" :class="{'filterChecked':isSelected(filter.filter,feature)}">
                                        <i class="filter-checkbox" >
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </i>
                                        <span v-html="feature"></span>
                                    </li>
                                </ul>
                            </div>



                        </div>
                    </smart-filter>
                </div>
            </section>

        </div>

    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            currentParam: this.$route.query,
            showFilter:false
        };
    },
    props: {
        filterOptions: {
            type: Array,
            default: null
        },
    },
    mounted(){
        window.addEventListener("resize", this.handleResize);
    },
    created(){

        let width=window.innerWidth;

        // first sort filterOptions based on filter_sort
        this.filterOptions.sort( (filter1, filter2) => (filter1.taxonomy.filter_sort > filter2.taxonomy.filter_sort) ? 1 : (filter1.taxonomy.filter_sort < filter2.taxonomy.filter_sort) ? -1 : 0);

        // second sort filterOption based on the filter_show
        this.filterOptions.sort( (filter1, filter2) => (filter1.taxonomy.filter_show < filter2.taxonomy.filter_show) ? 1 : (filter1.taxonomy.filter_show > filter2.taxonomy.filter_show) ? -1 : 0);

        if(width>1024){
            this.showFilter=true;
        }

    },

    computed: {
        ...mapState(["icons", "categories"]),
        filterCategories(){
            for(var i=0;i<this.categories.length;i++){

                if(this.categories[i].select_code.id==this.currentParam.scID){
                    return this.categories[i];
                }
            }
        },
        selectedCat() {
            return this.currentParam.hasOwnProperty('scID');
        },
        selectedSubCat() {
            return this.currentParam.hasOwnProperty('ccID');
        },

        checkforfilter(){
            if(Object.keys(this.currentParam).length === 1 || this.currentParam.hasOwnProperty('q')){
                return true;
            }else{
                return false;
            }

        },




    },
    methods: {
        handleResize(e){
            let width=window.innerWidth;

            let smartFilter=document.getElementsByClassName("filters-layout")[0];

            if (width>1024){
                smartFilter.classList.add("show");
            }else{
                smartFilter.classList.remove("show");
            }
        },

        // get the right icon for the provided filter
        getIcon(filter,value){
            var filterIcon=[];
            switch(filter){
                case 'ansi_cut':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name=='ansi-107-'+value){
                            return icon;
                        }
                    });

                break;
                case 'en388_A':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name=='en-abrasion-'+value){
                            return icon;
                        }
                    });
                break;
                case 'en388_P':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name=='en-puncture-'+value){
                            return icon;
                        }
                    });
                break;
                case 'en388_T':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name=='en-tear-'+value){
                            return icon;
                        }
                    });
                break;
                case 'en388_C':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name=='en-'+value){
                            return icon;
                        }
                    });
                break;
                case 'ansi_cut_9':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name=='ansi-'+value){
                            return icon;
                        }
                    });
                break;
                case 'hrc_level':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name=='ppe-'+value){
                            return icon;
                        }
                    });
                break;
                case 'electrical_class':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name==value.replace(/\s/g, "-").toLowerCase()){
                            return icon;
                        }
                    });
                break;
                case 'ansi_impact':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name=='ansi-impact-'+value){
                            return icon;
                        }
                    });

                break;
                case 'ansi_abrasion':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name=='ansi-abrasion-'+value){
                            return icon;
                        }
                    });
                break;
                case 'ansi_puncture':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name=='ansi-puncture-'+value){
                            return icon;
                        }
                    });
                break;
                case 'astm_f1060':
                    filterIcon=this.icons.filter(function(icon){
                        if(icon.name=='ansi-contact-heat-'+value){
                            return icon;
                        }
                    });
                break;

            }
            return filterIcon[0].svg;

        },

        // check if the param exists in the param and mark check on the input box
        isSelected(filterName,value){
            if(this.currentParam[filterName] !== undefined){
                let arrayofValues=this.currentParam[filterName].split('|');
                if(arrayofValues.find(element=> element==value)){
                    return true;
                }
            }else{
                return false;
            }
        },
        // adding new param with its value
        addNewParams(filterName,value) {
            // initializing the page number
            this.currentParam["page"]=1;

            const newParam={[filterName]: value};

            // Create new params object
            const mergedParams = { ...this.currentParam, ...newParam };

            // it simply tries to update current route with new params or query
            this.$router.push({ query: mergedParams });
        },
        // adding value to the existing param
        addValuetoParams(filterName,value){
            // initializing the page number
            this.currentParam["page"]=1;

            const updatedParam={[filterName]: this.currentParam[filterName]+'|'+value};
            delete this.currentParam[filterName];
            const mergedParams = { ...this.currentParam, ...updatedParam };
            this.$router.push({ query: mergedParams });
        },

        // delete value in param
        deleteValueinParam(filterName, value){
            // initializing the page number
            this.currentParam["page"]=1;

            // duplicate the object
            const params={...this.currentParam};
            // create an array of filter options
            const arrayofFilterOptions=params[filterName].split('|');

            // delete the value from the array
            arrayofFilterOptions.splice(arrayofFilterOptions.indexOf(value),1);

            if( arrayofFilterOptions.length>0){
                // join all the values from the array
                const updatedParam=arrayofFilterOptions.join('|');
                // updating the value in the filter
                params[filterName]=updatedParam;

                this.$router.push({ query: params });
            }else{
                //remove param itself
                delete params[filterName];

                this.$router.push({ query: params});
            }

        },

        // updating the url
        updateParams(filterName,value,filterSelect){
            // initializing the page number
            this.currentParam["page"]=1;

            if(!filterSelect){
                // check if the filter name exists
                if (this.currentParam[filterName] === undefined){
                    this.addNewParams(filterName,value);
                }else{
                    this.addValuetoParams(filterName,value);
                }
            }
            else{
                // removing  value from param
                this.deleteValueinParam(filterName, value);

            }
            // document.getElementById("main-container").scrollIntoView(true);
        },

        filterList(filterOption){
            let filteredList=[];
            let list=[... new Set(this.model.map(data => data[filterOption]))].sort();


            for(let i=0; i<list.length; i++){
                if(list[i].includes("|")){
                    let tempList=list[i].split("|").sort();
                    filteredList=[].concat(filteredList,tempList);
                }
                if(list[i] != "" && !list[i].includes("|")){
                    filteredList.push(list[i]);
                }
            }
            return filteredList.filter((element, index, array) => array.indexOf(element) === index);
        },

        // switch product layout on button click vertical or horizontal
        switchProdLayout(type){

            if(type=="vertical-layout"){
                document.getElementsByClassName("fa-th-large")[0].classList.add('prod-layout-select');
                document.getElementsByClassName("product-list")[0].classList.remove('product-horizontal-layout');
                document.getElementsByClassName("fa-list")[0].classList.remove('prod-layout-select');

            }else{
                document.getElementsByClassName("fa-th-large")[0].classList.remove('prod-layout-select');
                document.getElementsByClassName("product-list")[0].classList.add('product-horizontal-layout');

                document.getElementsByClassName("fa-list")[0].classList.add('prod-layout-select');
            }

        },

        filterHasValue(filterOption){
            return this.filterList(filterOption).length>0?true:false;
        },



    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },

};
</script>

<style lang="scss" scoped>


ul {
    list-style: none;
    font-size: 1rem;
    font-family: "acumin-pro", Helvetica, Arial, sans-serif;
}






// .active {
//     font-weight: 700;
//     color: #00549e;
// }
// .brand-link {
//     cursor: pointer;
//     color: #00549e;
// }



</style>
