import { render, staticRenderFns } from "./DefaultDashboard.vue?vue&type=template&id=d517e9b2&scoped=true"
import script from "./DefaultDashboard.vue?vue&type=script&lang=js"
export * from "./DefaultDashboard.vue?vue&type=script&lang=js"
import style0 from "./DefaultDashboard.vue?vue&type=style&index=0&id=d517e9b2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d517e9b2",
  null
  
)

export default component.exports